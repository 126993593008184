.Gallery {
    font-family: sans-serif;
    text-align: center;
  }
  
  body {
    margin: 0;
    left: 0;
  }
  .item {
    /* width: 250px; */
    opacity: 1;
  }
  .item .thumbnail {
    /* max-height: 300px; */
    overflow: hidden;
    border-radius: 3px;
  }
  .item .thumbnail img {
    width: 100%;
    border-radius: 3px;
  }
  .item .info {
    margin-top: 10px;
    font-weight: bold;
    color: #777;
  }
  .item:hover {
    opacity: 0.95;
    cursor: pointer;
  }
  .item.animate {
    transition: opacity ease 1s;
    transition-delay: 0.2s;
    opacity: 1;
  }
  .loading {
    position: absolute;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-weight: bold;
  }